;(function($){

/* ==========================================================================
 *
 * URI操作クラス
 *
 * global namespace: fabbit
 *
========================================================================== */
/* name space init
-------------------------------------*/
window.fabbit = window.fabbit || {};

/* constructor
---------------------------------------------- */
function uriUtils(){
	let _ = this;

	// ロケーション情報
	_.location = {
		hash: null,
		search: null,
		searchArray: {}
	};

};

/* ----------------------------------------------
 * URLパーサ
 * @ :
 * return :
---------------------------------------------- */
uriUtils.prototype.getUri = function(){
	let _ = this;

	_.location.href = location.href;
	_.location.hash = location.hash;
	_.location.pathname = location.pathname;
	_.location.search = location.search;
};

/* ディレクトリパーサ
 * @string:
 * return array = hash strings array
---------------------------------------------- */
uriUtils.prototype.parsePath = function(){
	let _ = this;

	// URIを更新
	_.getUri();

	//パスがなければfalse
	if(_.location.pathname.length < 1)
		return false;

	const pathname = _.location.pathname.substring(1, _.location.pathname.lastIndexOf('/'));

	if(pathname.length == 1){
		return false;
	}

	_.location.pathArray = pathname.split('/');

	return _.location.pathArray;
};

/* フラグメントパーサ
 * @string = uri location(パーサから確保)
 * return array = hash strings array
---------------------------------------------- */
uriUtils.prototype.parseHash = function(uriStr){
	let _ = this;

	// URIを更新
	_.getUri();

	//フラグメントがなければfalse
	if(_.location.hash.length < 1)
		return false;

	_.location.hash = _.location.hash.slice(1);

	return _.location.hash;
};

/* クエリパーサ
 * @string = uri location(パーサから確保)
 * return array = query strings array
---------------------------------------------- */
uriUtils.prototype.parseQuery = function(){
	let _ = this;

	// URIを更新
	_.getUri();

	//クエリーがなければfalse
	if(_.location.search.length < 1)
		return false;

	//クエリーを分割
	const query = _.location.search.slice(1).split('&');

	//クエリーを格納
	let queryArr = {};
	$.each(query,function(i,record){
		let params = {};
		params = record.split('=');
		queryArr[params[0]] = params[1];
	});

	//クエリ値を配列で返却
	_.location.query = queryArr;

	return _.location.query;
};

/* クエリシリアライズ
 * @string = uri location
	* return string = query string
---------------------------------------------- */
uriUtils.prototype.serializeQuery = function(data){
	let _ = this;

	let key = null;
	let query = '?';

	const encode = window.encodeURIComponent;

	// オブジェクトを展開
	for(key in data){
		const value = data[key];
		const type = typeof(value) === 'object' && value instanceof Array ? 'array' : typeof(value);

		switch (type) {
			case 'undefined':
				// キーのみ
				query += key;
				break;

			case 'array':
				// 配列
				for (i = 0, max = value.length; i < max; i++){
					query += key + '[]';
					query += '=';
					query += encode(value[i]);
					query += '&';
				};
				query = query.substr(0, query.length - 1);
				break;

			case 'object':
				// ハッシュ
				for (i in value) {
					query += key + '[' + i + ']';
					query += '=';
					query += encode(value[i]);
					query += '&';
				};
				query = query.substr(0, query.length - 1);
				break;

			default:
				query += key;
				query += '=';
				query += encode(value);
				break;
		};

		query += '&';
	};
	query = query.substr(0, query.length - 1);

	//クエリ値を配列で返却
	_.location.queryStr = query;

	return query;
};

/* ==========================================================================
 * プロジェクトインスタンス生成
========================================================================== */
fabbit.uri = $.extend( true ,new uriUtils(), fabbit.uri );

})(jQuery);
