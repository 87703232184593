;(function($){

/* ==========================================================================
 *
 * Ajax通信クラス
 *
 * global namespace: fabbit
 *
========================================================================== */
/* name space init
-------------------------------------*/
window.fabbit = window.fabbit || {};

/* constructor
---------------------------------------------- */
function ajaxUtils(){
	let _ = this;

	//初期設定
	_.defaultOption = {
		async: true,
		ul: '',
		type: 'GET',
		dataType: 'text',
		scriptCharset: 'utf-8',
		timeout: 5000,
		cache: false
	};

	// 最後の通信時のレスポンス格納先
	_.lastResponse = {};

	// 最後の通信時のログ格納先
	_.lastLog = {};
};

/* ----------------------------------------------
 * オプションマージ
 * @ :
 * return :
---------------------------------------------- */
ajaxUtils.prototype._mergeOption = function(ajaxArgs){
	let _ = this;

	if(ajaxArgs.length < 2){
		return false;
	};

	const ajax_url = ajaxArgs[0];
	const method = ajaxArgs[1];
	const post_data = typeof ajaxArgs[2] !== 'undefined' ? ajaxArgs[2] : null;
	const data_type = typeof ajaxArgs[3] !== 'undefined' ? ajaxArgs[3] : 'text';

	let option = {
		async: true,
		url: ajax_url,
		type: method,
		data: post_data,
		dataType: data_type,
		cache: false,
		beforeSend: function(xhr, set){
			// return;
		}
	};

	return $.extend( true, {}, _.defaultOption, option );
};

/* ----------------------------------------------
 * Ajaxリクエスト
 * @ :
 * return : data
---------------------------------------------- */
ajaxUtils.prototype.request = function(){
	let _ = this;

	// オプションのマージ
	const option = _._mergeOption(arguments);

	if(option === false){
		console.log('ajax:必須オプションが不足しています');
		return false;
	}

	// コールバックの取得
	const callbackFunc = typeof arguments[4] !== 'undefined' ? arguments[4] : null;

	// ajaxオブジェクト生成
	let ajaxObj = $.ajax(option);

	ajaxObj.done(function(data, stat, xhr){
		_.lastLog = {
			done: stat,
			data: data,
			xhr: xhr
		};

		if('script' !== option.data_type){
			_.lastResponse = {
				'responseText': xhr.responseText,
				'status': xhr.status,
				'statusText': xhr.statusText
			};
		} else {
			// 通信結果を格納
			_.lastData = data;

			return data;
		};

		if(callbackFunc === null){
			_.callback(data,xhr);
		}else{
			// 通信結果を格納
			_.lastData = data;

			// コールバック関数を実行
			if(typeof callbackFunc === 'function'){
				return callbackFunc(data,xhr);
			}else{
				return Callback[callbackFunc](data,xhr);
			}
		};
	});

	ajaxObj.fail(function(xhr, stat, err){
		_.lastLog = {
			fail: stat,
			error: err,
			xhr: xhr
		};
	});

	ajaxObj.always(function(res1, stat, res2){
		_.lastLog = {
			always: stat,
			res1: res1,
			res2: res2
		};

		if(stat === 'success'){
			console.log('通信完了');
		}
	});
};

/* ----------------------------------------------
 * callback設定用
 * @ :
 * return : data
---------------------------------------------- */
ajaxUtils.prototype.callback = function(){
};


/* ----------------------------------------------
 * htmlリクエスト
 * @ :
 * return :
---------------------------------------------- */
ajaxUtils.prototype.htmlRequest = function(){
};


/* ==========================================================================
 * プロジェクトインスタンス生成
========================================================================== */
fabbit.ajax = $.extend( true ,new ajaxUtils(), fabbit.ajax );

})(jQuery);
