;(function($){

/* ==========================================================================
 *
 * 基底スクリプト
 *
 * global namespace: fabbit
 *
========================================================================== */
/* name space init
-------------------------------------*/
window.fabbit = window.fabbit || {};

/* ==========================================================================
 * 最上位クラス
========================================================================== */
/* constructor
---------------------------------------------- */
function overall(){
	let _ = this;

	/* 使用変数定義
	-------------------------------------*/
	// クライアント情報
	_.userInfo = {
		// ブラウザの種類が格納される
		browser: null,
		// デバイスの種類が格納される
		device: null,
		// iOSのバージョンが格納される
		iOSVer: null,

		// 携帯、タブレット、タッチデバイス、iOSに関する情報が真偽値で格納される
		isMobile: null,
		isTablet: null,
		isTouch: null,
		isiOS: null
	};

	// サイト情報
	_.siteInfo = {
		// htmlに
		bodyClass: {},
		classPrefix: 'build-',

		// 画面サイズが格納される
		windowSize: {
			screen: {},
			inner: {},
		},

		// 画面幅に関する情報が文字列で格納される
		mode: null,
		// 画面幅に関する情報が真偽値で格納される
		isPC: null,
		isTB: null,
		isSP: null,
	};

	// ロケーション情報
	_.location = {
		hash: null,
		keys: {}
	};

	/* サイトオプション設定
	----------------------- */
	_.config = {
		reserved: {
			deviceChangeEvent: 'deviceChanged',
		},

		// ブレークポイント
		break: {
			sp: 740,
			tb: 740,
		},

		// グローバルヘッダーDOM
		elms: {
			header: '*[role="header"]',
			header: '*[role="footer"]'
		},
	};
};

/* ----------------------------------------------
 * initialize
 * @ :
 * return : obj
---------------------------------------------- */
overall.prototype.init = function(){
	let _ = this;

	/* 組み込みメソッド実行
	----------------------- */
	//ユーザーエージェント調査
	_.getUserAgent();

	// bodyクラス付与
	_.setBodyClass();

	//ウィンドウ情報更新
	_.setWindowInfo();

	// オプションメソッド実行
	_._initMethod();
};

/* ----------------------------------------------
 * メソッドinit
 * @ :
 * return : obj
---------------------------------------------- */
overall.prototype._initMethod = function(){
	let _ = this;
};

/* ----------------------------------------------
 * ユーザーエージェントやブラウザ情報を取得する
 * @ :
 * return : array
---------------------------------------------- */
overall.prototype.getUserAgent = function(){
	let _ = this;

	let ua = window.navigator.userAgent.toLowerCase();
	let ver = window.navigator.appVersion.toLowerCase();

	// ブラウザーチェック
	_.userInfo.browser = (function(){
		if (ua.indexOf('edge') !== -1) return 'edge';// Edge
		else if (ua.indexOf("iemobile") !== -1) return 'iemobile';// ieMobile
		else if (ua.indexOf('trident/7') !== -1) return 'ie11';// ie11
		else if (ua.indexOf("msie") !== -1 && ua.indexOf('opera') === -1){
			if (ver.indexOf("msie 6.")  !== -1) return 'ie6';// ie6
			else if (ver.indexOf("msie 7.") !== -1) return 'ie7';// ie7
			else if (ver.indexOf("msie 8.") !== -1) return 'ie8';// ie8
			else if (ver.indexOf("msie 9.") !== -1) return 'ie9';// ie9
			else if (ver.indexOf("msie 10.") !== -1) return 'ie10';// ie10
		}
		else if (ua.indexOf('chrome') !== -1 && ua.indexOf('edge') === -1)   return 'chrome';// Chrome
		else if (ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1) return 'safari';// Safari
		else if (ua.indexOf('opera') !== -1) return 'opera';// Opera
		else if (ua.indexOf('firefox') !== -1) return 'firefox';  // FIrefox
		else return 'unknown_browser';
	})();

	// デバイスチェック
	_.userInfo.device = (function(){
		if(ua.indexOf('iphone') !== -1 || ua.indexOf('ipod') !== -1 ) return 'iphone';
		else if (ua.indexOf('ipad') !== -1) return 'ipad';
		else if (ua.indexOf('android') !== -1) return 'android';
		else if (ua.indexOf('windows') !== -1 && ua.indexOf('phone') !== -1) return 'windows_phone';
		else return '';
	})();

	// iOS バージョンチェック
	_.userInfo.iOSVer = (function(){
		if ( /iP(hone|od|ad)/.test( navigator.platform ) ) {
			var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
			var versions = [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
			return versions[0];
		}
		else return 0;
	})();

	_.userInfo.isiOS = (_.userInfo.device === 'iphone' || _.userInfo.device === 'iPad');
	_.userInfo.isMobile = (ua.indexOf('mobi') !== -1 || _.userInfo.device === 'iphone' || (_.userInfo.device === 'windows_phone' && ua.indexOf('wpdesktop') === -1) || _.userInfo.device === 'iemobile');
	_.userInfo.isTablet = (_.userInfo.device === 'ipad' || (_.userInfo.device === 'android' && !_.userInfo.isMobile));
	_.userInfo.isTouch  = ('ontouchstart' in window);
	_.userInfo.isModern = !(_.userInfo.browser === 'ie6' || _.userInfo.browser === 'ie7' || _.userInfo.browser === 'ie8' || _.userInfo.browser === 'ie9' || (0 < _.userInfo.iOSVer && _.userInfo.iOSVer < 8));
};

/* ----------------------------------------------
 * bodyへユーザーエージェントクラスを付与する
 * @ :
 * return :
---------------------------------------------- */
overall.prototype.setBodyClass = function(){
	let _ = this;

	let bodyClass = {};

	bodyClass.browser = (_.userInfo.browser !== '') ? _.siteInfo.classPrefix+_.userInfo.browser : _.siteInfo.classPrefix+'browser-unknown';
	bodyClass.device = (_.userInfo.device  !== '') ? _.siteInfo.classPrefix+_.userInfo.device : _.siteInfo.classPrefix+'device-unknown';
	bodyClass.isMobile = (_.userInfo.isMobile) ? _.siteInfo.classPrefix+'mobile' : _.siteInfo.classPrefix+'pc';
	bodyClass.isTouch = (_.userInfo.isTouch) ? _.siteInfo.classPrefix + 'touch' : _.siteInfo.classPrefix +'mouse';
	bodyClass.isModern = (_.userInfo.isModern) ? _.siteInfo.classPrefix+'modern' : _.siteInfo.classPrefix+'old';

	$(document).on('ready',function(){
		// 以前のbodyタグを削除
		if (_.siteInfo.bodyClass) {
			Object.keys(bodyClass).forEach(function (key) {
				let val = bodyClass[key];
				$('body').removeClass(val);
			});
		};

		// bodyタグを付与
		Object.keys(bodyClass).forEach(function (key) {
			let val = bodyClass[key];
			$('body').addClass(val);
		});

		// bodyタグを格納
		_.siteInfo.bodyClass = bodyClass;
	});
};

/* ----------------------------------------------
 * ウィンドウ情報取得イベントのセット
 * @ :
 * return :
---------------------------------------------- */
overall.prototype.setWindowInfo = function(){
	let _ = this;

	// windowサイズ取得
	$(window).on('resize',$.proxy(_.getWindowSize, _));

	// resizeイベントを即時発火
	$(window).triggerHandler('resize');
};

/* ----------------------------------------------
 * ウィンドウ幅取得
 * @ :
 * return :
---------------------------------------------- */
overall.prototype.getWindowSize = function(){
	let _ = this;

	// スクリーンサイズを取得
	let screen = [];
	screen.width = window.parent.screen.width;
	screen.height = window.parent.screen.height;
	// オプションへ格納
	_.siteInfo.windowSize.screen = screen;

	// ウィンドウサイズを取得
	let inner = [];
	inner.width = window.innerWidth;
	inner.height = window.innerHeight;
	// オプションへ格納
	_.siteInfo.windowSize.inner = inner;

	/* ブレークポイント判定
	-------------------------------------*/
	//ブラウザ幅がブレークポイントより大きい(PC)
	if(inner.width > _.config.break.tb){
		checkDevice('pc');

	//ブラウザ幅がブレークポイントより大きい(TB、PC)
	}else if(inner.width > _.config.break.sp){
		checkDevice('tb');

	//ブラウザ幅がブレークポイントより小さい(SP)
	}else{
		checkDevice('sp');
	};

	/* デバイス変更判定
	-------------------------------------*/
	function checkDevice(device){
		if(_.siteInfo.mode !== device){
			// デバイスモードと真偽値更新
			_.siteInfo.mode = device;

			if(_.siteInfo.mode === 'pc'){
				_.siteInfo.isPC = true;
				_.siteInfo.isTB = false;
				_.siteInfo.isSP = false;

			}else if(_.siteInfo.mode === 'tb'){
				_.siteInfo.isPC = false;
				_.siteInfo.isTB = true;
				_.siteInfo.isSP = false;

			}else{
				_.siteInfo.isPC = false;
				_.siteInfo.isTB = false;
				_.siteInfo.isSP = true;
			}

			$(window).triggerHandler(_.config.reserved.deviceChangeEvent);
			$(document).triggerHandler(_.config.reserved.deviceChangeEvent);
		}
	};
};

/* ==========================================================================
 * プロジェクトインスタンス生成
========================================================================== */
window.fabbit.overall = $.extend( true ,new overall(), window.fabbit.overall );

//基底スクリプトスタート
window.fabbit.overall.init();


})(jQuery);
